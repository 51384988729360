import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { PageIntro, NewsletterForm, FormSection } from '#sections'
import { SectionTitle, Layout, PostBlock, ArrowButton } from '#components'

function Posts({ pageContext: { title, posts, featuredImage } }) {
  const [perPage, setPerPage] = useState(7)
  const [currPosts, setCurrPosts] = useState(posts.slice(0, perPage))

  useEffect(() => {
    setCurrPosts(posts.slice(0, perPage))
  }, [perPage, posts])

  return (
    <Layout title={title}>
      <PageIntro backgroundImage={featuredImage} />
      <div className="bg-white relative pb-55 md:pb-65">
        <div className="container pt-40 md:pt-55 pb-25 lg:pb-55">
          <SectionTitle title={title} />

          <div className="flex flex-wrap -mx-7 pt-35 md:pt-50">
            {!!posts &&
              currPosts.map((post, index) => (
                <div
                  className={`mb-25 w-full px-7 ${
                    index !== 0 ? `md:w-1/2 lg:w-1/3` : ``
                  }`}
                  key={post.uri}
                >
                  <PostBlock
                    size={index === 0 ? `large` : `small`}
                    post={post}
                  />
                </div>
              ))}
          </div>

          {perPage < posts.length && (
            <div className="text-center pt-40">
              <ArrowButton
                variant="accent"
                type="button"
                onClick={() => setPerPage(perPage + 6)}
              >
                Load More
              </ArrowButton>
            </div>
          )}
        </div>
      </div>
      <NewsletterForm />
      <FormSection />
    </Layout>
  )
}

Posts.propTypes = {
  pageContext: PropTypes.object,
}

export default Posts
